import React, { useEffect, useContext } from "react";
import { AppContext } from "../../components/Layout/context";
import { useStaticQuery, graphql } from "gatsby";
import Events from "./Events";
import EventsStages from "./EventsStages";
import Explore from "./Explore";
import Header from "./Header";
import SignUp from "./SignUp";
import SEO from "../../components/SEO"

function Index() {
  const { lan, setLang } = useContext(AppContext);

  useEffect(() => {
    const languagekey = localStorage.getItem("lankey");
    if (languagekey === null) {
      localStorage.setItem("lankey", "en");
      setLang("en");
    }
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allStrapiHomePageV1 {
        edges {
          node {
            locale
            hero_heading1
            hero_description
            hero_purple_word
            HeroBtn {
              name
              to
            }
            HeroLearnMore {
              name
              to
            }
            hero_image {
              url
              alternativeText
            }
            Service1Heading
            Service1Img {
              url
              alternativeText
            }
            Service2Heading
            Service2Img {
              url
              alternativeText
            }
            Service3Heading
            Service3Img {
              alternativeText
              url
            }
            Service4Heading
            Service4Img {
              url
              alternativeText
            }
            Service5Heading
            Service5Img {
              url
              alternativeText
            }
            service5Description
            service4Description
            service3Description
            service2Description
            service1Description
            previewOGImg {
              url
              alternativeText
            }
          }
        }
      }
    }
  `);

  const homeData = data.allStrapiHomePageV1?.edges;
  const filteredData = homeData.filter(({ node }) => node.locale == lan);
  // Header Data
  const hero_heading = filteredData?.[0]?.node?.hero_heading1;
  const purple_word = filteredData?.[0]?.node?.hero_purple_word;
  const hero_description = filteredData?.[0]?.node?.hero_description;
  const HeroBtn = filteredData?.[0]?.node?.HeroBtn;
  const HeroBtnLearnMore = filteredData?.[0]?.node?.HeroLearnMore;
  const hero_image = filteredData?.[0]?.node?.hero_image;

  // Events
  const service1Heading = filteredData?.[0]?.node?.Service1Heading;
  const service1Description = filteredData?.[0]?.node?.service1Description;
  const service1Img = filteredData?.[0]?.node?.Service1Img;

  // EventsStages
  const service2Heading = filteredData?.[0]?.node?.Service2Heading;
  const service2Description = filteredData?.[0]?.node?.service2Description;
  const service2Img = filteredData?.[0]?.node?.Service2Img;
  const service3Heading = filteredData?.[0]?.node?.Service3Heading;
  const service3Description = filteredData?.[0]?.node?.service3Description;
  const service3Img = filteredData?.[0]?.node?.Service3Img;
  const service4Heading = filteredData?.[0]?.node?.Service4Heading;
  const service4Description = filteredData?.[0]?.node?.service4Description;
  const service4Img = filteredData?.[0]?.node?.Service4Img;
  const service5Heading = filteredData?.[0]?.node?.Service5Heading;
  const service5Description = filteredData?.[0]?.node?.service5Description;
  const service5Img = filteredData?.[0]?.node?.Service5Img;

  return (
    <div className="bg-bgBlack">
      <>
        <Header
          hero_heading={hero_heading}
          purple_word={purple_word}
          hero_description={hero_description}
          HeroBtn={HeroBtn}
          HeroBtnLearnMore={HeroBtnLearnMore}
          hero_image={hero_image}
        />
        <SEO 
          title="Happen Space"
          description="Metaverse For events. Network and meet people during events"
        />
        <div id="Fragment">
          <Events
            service1Heading={service1Heading}
            service1Description={service1Description}
            service1Img={service1Img}
          />
        </div>
        <EventsStages
          service2Heading={service2Heading}
          service3Heading={service3Heading}
          service4Heading={service4Heading}
          service5Heading={service5Heading}
          service2Description={service2Description}
          service3Description={service3Description}
          service4Description={service4Description}
          service5Description={service5Description}
          service2Img={service2Img}
          service3Img={service3Img}
          service4Img={service4Img}
          service5Img={service5Img}
        />
        <div id="explore">
          <Explore lan={lan} />
        </div>
        <div id="Signup">
          <SignUp />
        </div>
      </>
    </div>
  );
}

export default Index;
