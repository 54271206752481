import React from "react";

import ExploreCards from "./ExploreCards";
import { useStaticQuery, graphql, Link } from "gatsby";

const Explore = ({ lan }) => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiPost(sort: {fields: home_order}, filter: {home_order: {ne: 0}}){
        edges {
          node {
            locale
            id
            title
            slug
            body
            home_order
            date(formatString: "DD-MM-YYYY")
            image {
              url
              alternativeText
            }
          }
        }
      }
    }
  `);
  const posts = data.allStrapiPost?.edges;
  const filteredData = posts.filter(({ node }) => node.locale.includes(lan));

  return (
    <div>
      <div className="max-w-[1170px]  mx-auto text-white md:text-left text-center md:pt-[100px] pt-[70px] lg:pb-[100px] pb-2">
        <div>
          <h1 className="font-medium text-[46px] pb-[21px] pl-[35px]">Explore</h1>
          <div className="flex gap-3 pl-[35px] md:justify-between lg:flex-nowrap flex-wrap md:flex-row flex-col justify-center">
            <p className="font-medium text-lg text-primary">
              Insights from our team, user success stories, and how to get
              started.
            </p>
            <Link
              to="/explore"
              className="max-w-[134px] md:mx-0 mx-auto font-bold text-lg text-secondary"
            >
              View All Posts
            </Link>
          </div>
        </div>
        <div>
          <ExploreCards posts={filteredData} />
        </div>
      </div>
    </div>
  );
};

export default Explore;
