import React from "react";
function Events({ service1Heading, service1Description, service1Img }) {
  return (
    <div>
      <div className="main-container lg:pt-[85px] pt-12">
        <div className="grid md:grid-cols-2  grid-cols-1 place-items-center">
          <div>
            <div className="max-w-[475px] md:text-left text-center">
              <h3 className="font-semibold text-white text-[28px] mb-5">
                {service1Heading}
              </h3>
              <p className="text-primary text-base font-medium">
                {service1Description}
              </p>
            </div>
          </div>
          <div>
            <div className="flex md:justify-start justify-center md:pt-0 pt-7">
              <img
                src={service1Img !== undefined ? service1Img.url : ""}
                alt={
                  service1Img !== undefined ? service1Img.alternativeText : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
