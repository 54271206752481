import React from "react";
import HeaderLeft from "./../../images/Group 16 Copy 3.png";
import Headertopright from "./../../images/Combined Shape.png";
import Headerright from "./../../images/Group 16 Copy 5.png";
import HeroVDO from "./../../images/hero_video.mp4";
import VDOPoster from "./../../images/vdo_poster.png";
import { Link } from "gatsby"

import AnchorLink from "react-anchor-link-smooth-scroll";

function Header({
  hero_heading,
  purple_word,
  hero_description,
  HeroBtn,
  HeroBtnLearnMore,
  hero_image,
}) {
  return (
    <div>
      <div className="main-conatiner text-white lg:pt-[85px]  pt-20">
        <div className="inner-container text-center">
          <h1 className="font-medium  lg:text-[46px] text-3xl leading-[56px] pb-[21px]">
            {hero_heading}
            <span className="Communities mx-3">{purple_word}</span>
          </h1>
          <p className="pb-[35px] font-medium md:text-lg text-base">
            {hero_description}
          </p>
          <div className="flex justify-center pb-[85px]">
            <div className="flex ">
              <div className="text-sm font-semibold mx-[8px] sm:mx-[13px]">
                {HeroBtn?.to[0] === "#" ? (
                  <AnchorLink
                    href={HeroBtn?.to}
                    className=" px-4 py-[14px] rounded-[7px] text-sm font-semibold text-white  header-btn capitalize"
                  >
                    {HeroBtn?.name}
                  </AnchorLink>
                ) : (
                  <Link to={HeroBtn?.to}>
                    <span className="px-4 py-[14px] rounded-[7px] text-sm font-semibold text-white  header-btn capitalize">
                      {HeroBtn?.name}
                    </span>
                  </Link>
                )}
              </div>
              <div className="text-sm font-semibold mx-[8px] sm:mx-[13px]">
                <AnchorLink href={HeroBtnLearnMore?.to}>
                  {HeroBtnLearnMore?.name}
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="relative pb-10">
            <div>
              <img
                src={HeaderLeft}
                alt=""
                className="absolute -left-[100px] -top-[103px]  hide-923"
              />
              <img
                src={Headertopright}
                alt=""
                className="absolute -right-[100px] w-[350px] lg:-top-[171px] -top-[200px]  lg:block hidden"
              />
            </div>
            <div className="iframe-wrapper border-[#7D7880]">
              <video
                preload="auto"
                autoPlay={true}
                loop={true}
                controls={false}
                playsInline
                muted
                className="responsive-iframe"
                poster={VDOPoster}
              >
                <source src={HeroVDO} type="video/mp4" />
              </video>
            </div>
            <img
              src={Headerright}
              alt=""
              className="absolute -right-[50px] -bottom-[33px] z-[2] hide-923"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header;
