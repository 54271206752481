import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "gatsby";
import slugify from "slugify";

const ExploreCards = ({ posts }) => {
  return (
    <div className="main-container overflow-hidden">
      <div className=" py-12 ">
        <div className="md:grid sm:grid-cols-2 sm:gap-5  lg:grid-cols-4  ">
          {posts?.map((post) => (
            <div
              key={post.node.id}
              className=" bg-white max-w-[275px]  mx-auto mb-6 rounded-[10px] relative text-left"
            >
              <div>
                <img
                  src={post.node.image.url}
                  alt={post.node.image.alternativeText}
                  className="rounded-t-[11px] min-h-[240px] object-cover"
                />
                <div className=" p-6 flex flex-col justify-between gap-y-[31px]">
                  <div className="font-medium text-lg leading-6 space-y-1 tooltip">
                    <h3 className="text-black font-semibold text-base mb-[50px] line-clamp-3">
                      {post.node.title}
                      <span className="tooltiptext">{post.node.title}</span>
                    </h3>
                  </div>
                  <div className="absolute bottom-6 md:bottom-11">
                    <Link
                      to={`/explore/${slugify(post.node.slug, {
                        lower: true,
                      })}`}
                      className="text-sm uppercase flex gap-1 items-center font-semibold text-secondary"
                    >
                      Read More
                      <span>
                        <MdKeyboardArrowRight className="text-lg" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExploreCards;
