import React from "react";
import Index from "../views/Home/Index";
import Layout from "../components/Layout/Layout";

const IndexPage = () => {
  return (
    <div className="bg-bgBlack">
      <Layout>
        <div className="text-3xl">
          <Index />
        </div>
      </Layout>
    </div>
  );
};

export default IndexPage;
