import React from "react";
import Markdown from "react-markdown";
function EventsStages({
  service2Heading,
  service3Heading,
  service4Heading,
  service5Heading,
  service2Description,
  service3Description,
  service4Description,
  service5Description,
  service2Img,
  service3Img,
  service4Img,
  service5Img,
}) {
  return (
    <div>
      <div className="serviceTwoBg">
        <div className="main-container  pt-[150px] lg:pb-[52px] md:pb-24 pb-14">
          <div className="max-w-[825px]  mx-auto px-5 text-center mb-[50px]">
            <h2 className="text-[28px] font-semibold text-white mb-5">
              {service2Heading}
            </h2>
            <p className="font-medium text-base text-primary">
              <Markdown children={service2Description} escapeHtml={false} />
            </p>
          </div>

          <div className="flex justify-center">
            <img
              src={service2Img !== undefined ? service2Img.url : ""}
              alt={service2Img !== undefined ? service2Img.alternativeText : ""}
            />
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="grid md:grid-cols-2 gap-3  grid-cols-1 place-items-center pt-[57px] lg:pt-[37px]">
          <div>
            <div className="max-w-[475px] md:text-left text-center">
              <h3 className="font-semibold text-white text-[28px] mb-5">
                {service3Heading}
              </h3>
              <p className="text-primary text-base font-medium">
                <Markdown children={service3Description} escapeHtml={false} />
              </p>
            </div>
          </div>
          <div>
            <div className="serviceThreeBg">
              <div className="flex md:justify-start justify-center md:pt-0 pt-7">
                <img
                  src={service3Img !== undefined ? service3Img.url : ""}
                  alt={
                    service3Img !== undefined ? service3Img.alternativeText : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="grid md:grid-cols-2 gap-3  grid-cols-1 place-items-center pt-[57px] lg:pt-[37px]">
          <div className="md:order-1 order-2">
            <div className="serviceFourBg">
              <div className="flex  justify-center md:pt-0 pt-7 ">
                <img
                  src={service4Img !== undefined ? service4Img.url : ""}
                  alt={
                    service4Img !== undefined ? service4Img.alternativeText : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="md:order-2 order-1">
            <div className="max-w-[475px] md:text-left text-center">
              <h3 className="font-semibold text-white text-[28px] mb-5 mt-5">
                {service4Heading}
              </h3>
              <p className="text-primary text-base font-medium">
                <Markdown children={service4Description} escapeHtml={false} />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="main-container">
        <div className="grid md:grid-cols-2 gap-3  grid-cols-1 place-items-center pt-[57px] lg:pt-[37px]">
          <div>
            <div className="max-w-[475px] md:text-left text-center">
              <h3 className="font-semibold text-white text-[28px] mb-5">
                {service5Heading}
              </h3>
              <p className="text-primary text-base font-medium">
                <Markdown children={service5Description} escapeHtml={false} />
              </p>
            </div>
          </div>
          <div className="serviceFiveBg">
            <div className="relative ">
              <div className="flex md:justify-start justify-center md:pt-[180px] md:pb-[180px] pt-7">
                <img
                  src={service5Img !== undefined ? service5Img.url : ""}
                  alt=""
                  className="max-w-[340px] mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventsStages;
